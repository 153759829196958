import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Onryo() {
    return (
        <div>
            <div>Onryos don't like fire and may start a hunt once the fire goes out.</div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Spirit Box" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Orbs" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Behavior">
                    <ul>
                        <li>Kills fire (candle, matches, camp fire) at a normal rate as other ghosts. The more players are dead however, the more likely it is that the Onryo will kill a fire.</li>
                        <li>At under 60% sanity it will kill fires more often, because it's trying to hunt.</li>
                        <li>Tip: put a candle on top of a crucifix. If the crucifix burns up but the candle is not blown out, it's <em>not</em> an Onryo.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    <ul>
                        <li>If a lit candle is around, it prevents a hunt. Instead the candle is blown out. If a cruxifix and candle are both present, then 
                    then candle takes priority.</li>
                        <li>Every third killed flame will cause a hunt, regardless of sanity level.</li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <OnryoSanityIndicator /> or lower.</li>
                        <li>Attempts to hunt after three killed flames. (see ability)</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function OnryoSanityIndicator() {
    return (
        <>
        <SanityIndicator sanity={60} />
        <span style={{ opacity: 0.5 }}><SanityIndicator sanity={100} /></span>
        </>
    );
}

export function OnryoSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />
}