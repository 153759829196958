import EvidendeIndicator from "../../evidence/EvidenceIndicator";
import GhostInfoSection from "../GhostInfoSection";
import SanityIndicator from "../SanityIndicator";
import SpeedIndicator, { speedExactly } from "../SpeedIndicator";

export default function Demon() {
    return (
        <div>
            <div className="ghost-details">
                <GhostInfoSection title="Evidence">
                    <ul className="list-unstyled">
                        <li><EvidendeIndicator evidenceType="Ultraviolet" /></li>
                        <li><EvidendeIndicator evidenceType="Ghost Writing" /></li>
                        <li><EvidendeIndicator evidenceType="Freezing" /></li>
                    </ul>
                </GhostInfoSection>
                <GhostInfoSection title="Special Ability">
                    Can start a hunt at any time, regardless of average sanity.
                </GhostInfoSection>
                <GhostInfoSection title="Hunt">
                    <ul>
                        <li>Hunts at <DemonSanityIndicator /> or lower, or uses ability to hunt at any time.</li>
                        <li>Cooldown after hunt is 20s instead of 25s.</li>
                        <li>Smudging prevents a hunt for 60s instead of 90s.</li>
                        <li>Crucifix has a 50% larger range.</li>
                    </ul>
                </GhostInfoSection>
            </div>
        </div>
    );
}

export function DemonSanityIndicator() {
    return (
        <>
        <SanityIndicator sanity={70} />
        <span style={{ opacity: 0.5 }}><SanityIndicator sanity={100} /></span>
        </>
    );
}

export function DemonSpeedIndicator() {
    return <SpeedIndicator speed={speedExactly(1.7)} />;
}